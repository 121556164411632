import Vue from 'vue'
import loading from 'vuejs-loading-screen'

Vue.use(loading, {
    bg: 'rgb(0 0 0 / 0.5)',
    slot: `
        <div class="px-5 py-3 bg-gray-800 rounded">
        <div class="loading-container"></div>
        </div>
    `
})
